
import moment from 'moment'
import axios from '@/services/axios'
import { formatterDollars } from '@/utils/formatter'
import { defineComponent, ref, computed, reactive, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { useNotification } from '@/composables'

export default defineComponent({
  components: {
    Pagination,
    FilterTable,
    sectionLayoutContent,
  },

  setup() {
    const totalSeller = ref<any>(0)
    const creditSeller = ref<any>(0)

    const { success, error } = useNotification()

    const breadcrumbs = ['Invoices', '']
    const data = reactive({
      daterange: '',
    })
    const params = reactive<any>({
      page: 1,
      limit: 10,
      type: '',
      // invoiceName: '',
      invoiceId: '',
      invoiceDate: {
        startDate: '',
        endDate: '',
        timezone: '',
      },
    })

    const { documents, fetchData, total } = useFetchData('invoices', params)
    const isLoading = ref(false)
    const content = computed(() => {
      return { breadcrumbs }
    })
    const onChangeDate = async () => {
      if (data.daterange?.length > 1) {
        let startDate = moment(data.daterange[0]).format('YYYY-MM-DD')
        let endDate = moment(data.daterange[1]).format('YYYY-MM-DD')
        params.invoiceDate = {
          startDate: startDate,
          endDate: endDate,
          timezone: 'America/Los_Angeles',
        }
      } else {
        delete params.invoiceDate
      }
    }
    const loadData = async () => {
      isLoading.value = true

      await fetchData()

      isLoading.value = false
    }

    const loadTotalPayment = async () => {
      const resource = 'invoices/payment/statistics'
      const res = await axios.get(resource)
      totalSeller.value = res.data.debitTotal
      creditSeller.value = res.data.creditTotal
    }

    watch(params, loadData)

    loadData()
    loadTotalPayment()
    const onFilterCallback = (filter: any) => {
      let search = filter?.search
      // params.invoiceName = search
      params.invoiceId = search
    }

    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }

    const onExport = async (row: any) => {
      try {
        let res = await axios.get(`invoices/${row._id}/export`)
        downloadFile('export.csv', res.data.url)
      } catch (err) {
        console.log(err)
      }
    }

    const { exportOrder } = useOrderRepositories()

    const onExportInvoice = async (row: any) => {
      let res = null
      try {
        if (row.type === 'OUT') {
          res = (await exportOrder({
            invoice: row._id,
          })) as any
        } else {
          res = (await exportOrder({
            rejectedInvoiceId: row._id,
          })) as any
        }
        downloadFile(`Export-invoice.csv`, res?.data?.url)
        success(res?.data?.message || `Export success`)
      } catch (e) {
        error()
      }
    }

    return {
      moment,
      documents,
      params,
      data,
      total,
      content,
      isLoading,
      onExport,
      totalSeller,
      creditSeller,
      onFilterCallback,
      formatterDollars,
      onChangeDate,
      exportOrder,
      onExportInvoice,
    }
  },
})
